<template>
  <div v-if="BrandSetting?.recaptcha_information?.for_registration" align="left">
    <vue-recaptcha
        :sitekey="BrandSetting?.recaptcha_information?.site_key"
        @verify="onVerify"
        :language="locale"
    />
  </div>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha'

export default {
  name: 'recaptchaComponent',
  props: {
    resetToken: {
      type: Boolean,
      request: false,
      default: false
    }
  },
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      locale: this.$i18n.locale,
    }
  },
  computed: {
    BrandSetting() {
      return this.$store.getters["profile/getBandSettingMedia"] || JSON.parse(localStorage.getItem('authentication'))
    },
  },
  created() {
    if (!this.BrandSetting?.recaptcha_information?.for_registration) {
      this.$emit('verify')
    }
  },
  methods: {
    onVerify(e) {
      this.$emit('token', e)
      this.$emit('verify', true)
    },
    resetVerify() {
      if (this.BrandSetting?.recaptcha_information?.for_registration) {
        this.$emit('token', null)
        grecaptcha.reset()
      } else {
        this.$emit('token', '')
      }
      this.$emit('verify', false)
    }
  },
  watch: {
    resetToken(newVal) {
      if (newVal) {
        this.resetVerify()
      }
    }
  }
}

</script>


